<template>
    <div>
        <transition name="fade" mode="out-in">
            <div :class="{'container-fluid': !fullScreen}">
                <div v-if="ready && recordStore" class="form-row">
                    <app-notification
                        :notifications.sync="recordStore.notifications"
                        :dependencies="recordStore.dependencies"
                        :id="recordStore.id"
                        :tableName="recordStore.tableName"
                        class="mb-2"
                    ></app-notification>

                    <div class="col-md-12 form-row row-booking-title" v-show="!fullScreen">
                        <div class="col-lg-6 col-xs-10">
                            <label>
                                <h5>
                                    <span v-if="recordStore.notTemplate">{{getName}} {{ recordStore.titleName }}</span>
                                    <span v-else>Template {{ recordStore.titleName }}</span>
                                    <i v-if="recordStore && !recordStore.id">({{tr('New')}})</i>
                                    <i v-else-if="modified" class="record-status"> - {{tr('Modified')}}</i>
                                    <i v-if="versionId" class="text-danger"> {{tr('Version')}} {{ versionId }} - {{versionComment}}</i>
                                    <!--span class="text-danger ml-2" v-if="recordBlocked">{{recordBlocked}}</span-->
                                </h5>
                            </label>
                        </div>
                        <div class="col-lg-6 col-xs-12">
                            <breadcrumb
                                :list="[{title: module}, {title: pathName, route: pathRoute}, {title: recordStore.Title}]"
                            ></breadcrumb>
                        </div>
                    </div>
                    <booking-nav-items
                         ref="navItems"
                         v-show="!fullScreen"
                         :version="version"
                    ></booking-nav-items>
                    <div class="w-100">
                        <booking-tabs-content
                          v-if="showTabs"
                          :version="version"
                        ></booking-tabs-content>
                    </div>
                    <div class="col-md-1 buttons-col" v-show="(!fullScreen && !version) || user.UserName == 'admin'">
                        <booking-button-actions
                            :qNotes="qNotes"
                            @showDelete="showDelete"
                            class="desktop"
                        ></booking-button-actions>
                    </div>

                </div>
                <edited-by :record="recordStore" class="text-small"></edited-by>
            </div>
        </transition>
        <input-value
            v-if="showVersionInput"
            @save="saveVersion"
            @close="showVersionInput = false"
            :text="tr('Add Comment')">
        </input-value>
        <confirm-delete
            v-if="canDelete && toDelete"
            @confirmRemove="remove()"
            :toDelete.sync="toDelete"
        ></confirm-delete>
        <confirm-close
            v-if="askSave"
            @cancelClose="cancelClose"
            @confirmClose="confirmClose"
            @saveClose="saveClose"
         ></confirm-close>
         <booking-versions
            v-if="showBookingVersions && recordStore"
            :bookingId="recordStore.id"
            :showTabs.sync="showTabs2"
            @close="showBookingVersions = false"
         ></booking-versions>
        <booking-notes
            v-if="notes && recordStore"
            :id="recordStore.id"
            :show.sync="notes"
        ></booking-notes>
        <booking-share
            :record.sync="record"
            :bookingShare.sync="bookingShare"
            v-if="bookingShare"
        ></booking-share>
        <select-value 
            v-if="bookingFormActions" 
            @save="setBookingFormAction" 
            :show.sync="bookingFormActions"
            text="Action" 
            :fieldOptions="bookingFormActions" 
            defValue="VIEW"
        ></select-value>
        <booking-form-view
            v-if="viewBookingForm && recordStore"
            :id="viewBookingForm"
            :show.sync="viewBookingForm"
        ></booking-form-view>
        <booking-alerts
            v-if="alerts"
            @close="closeBookingAlerts"
        ></booking-alerts>
        <booking-views
            v-if="views"
            :show.sync="views"
        ></booking-views>
        <booking-changes
            v-if="changes"
            :show.sync="changes"
        ></booking-changes>
        <booking-backups
            v-if="backups"
            :show.sync="backups"
        ></booking-backups>
    </div>
</template>


<script>
import recordWindow from '@/components/tools/RecordWindow';
const bookingNavItems = importVueComp('components/booking', 'BookingNavItems');
const bookingTabsContent = importVueComp('components/booking', 'BookingTabsContent');
const bookingButtonActions = importVueComp('components/booking', 'BookingButtonActions');
const bookingVersions = importVueComp('components/booking', 'BookingVersions');
const bookingNotes = importVueComp('components/booking', 'BookingNotes');
const bookingShare = importVueComp('components/booking', 'BookingShare');
const bookingFormView = importVueComp('components/booking', 'BookingFormView');
const bookingAlerts = importVueComp('components/booking', 'BookingAlerts');
const bookingViews = importVueComp('components/booking', 'BookingViews');
const bookingChanges = importVueComp('components/booking', 'BookingChanges');
const bookingBackups = importVueComp('components/booking', 'BookingBackups');
var EventBus = require('@/tools/event-bus').default;
import Vue from 'vue';
import { mapState } from 'vuex';
let bookingOptions = api.tableOptions.booking;
if (!bookingOptions) bookingOptions = api.getTableParams('booking', {});

var c = Vue.component('record-booking-window', {
    extends: recordWindow,
    props: {
        table: {
            type: String,
            default: 'booking'
        },
        id: {
            type: String
        },
        newBooking: {
            type: Object
        },
        sales: {
            type: Boolean
        },
        template: {
            type: String
        },
        version: {
            type: String
        }
    }
});

export default {
    name: 'booking-window',
    mixins: [c],
    components: {
        'booking-nav-items': bookingNavItems,
        'booking-tabs-content': bookingTabsContent,
        'booking-button-actions': bookingButtonActions,
        'booking-versions': bookingVersions,
        'booking-form-view': bookingFormView,
        'booking-notes': bookingNotes,
        'booking-share': bookingShare,
        'booking-alerts': bookingAlerts,
        'booking-views': bookingViews,
        'booking-changes': bookingChanges,
        'booking-backups': bookingBackups,
    },
    data () {
        return {
            intervalTabs: false,
            tabsLength: null,
            showVersionInput: false,
            showBookingVersions: false,
            notes: false,
            bookingShare: false,
            bookingFormActions: false,
            viewBookingForm: null,
            alerts: false,
            views: false,
            changes: false,
            backups: false,
            alertsNextSave: false,
            recorderInterval: null,
            versionId: null,
            versionComment: null,
            showEmail: false,
            bkpTime: 1000 * 5 * 60,
            bkpStatus: false,
            qNotes: null,
        }
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            fullScreen: state => state.main.fullScreen,
            currentTab: state => state.booking.currentTab,
            recordStore: state => state.main.record,
        }),
        getName () {
            if (this.recordStore && this.recordStore.bookingConfirmed) return 'File';
            return tr('Quotation');
        },
        bookingId () {
            return (this.recordStore && this.recordStore.id) ? this.recordStore.id : null;
        }   
    },
    async mounted () {
        if (!this.currentTab) {
            this.setCurrentTab('cover');
        } else {
            $('#langTabs .nav-item #' + this.currentTab).tab('show');
        }
        this.setIntervalTabs();
        if (!this.version) this.startRecorder();
        EventBus.$on('close-bookingform', this.closeBookingForm);
        EventBus.$on('bkp-booking', this.bkpBooking);
    },
    watch: {
        bookingId () {
            if (this.bookingId) {
                this.getNotes();
                this.getNotesQuantity();
            }
        }
    },
    methods: {
        startRecorder () {
            this.recorderInterval = setInterval(() => {
                this.setToLocalStorage();
            }, 10000);
        },
        setToLocalStorage () {
            if (this.record && this.record.Title && this.record.StartDate && this.record.LanguageId) {
                let record = tools.plainObject(this.recordStore);
                tools.cleanRelations(record);
                tools.clean(record);
                localStorage.setItem('recordState', JSON.stringify(record));
            }
        },
        bkpBooking () {
            //console.log('receive bkp-booking');
            if (!self.bkpStatus) {
                self.bkpStatus = true;
                this.setBackup();
                setTimeout(() => {
                    self.bkpStatus = false;
                }, self.bkpTime)
            }
        },
        setBackup () {
            if (!this.recordStore) return;
            if (!this.recordStore.Title) return;
            if (!this.modified && !this.isNew) return;
            if (this.recordStore.bookingConfirmed) return;
            try {
                frontTools.getFromIndexDB('bookingBkp', 'bkp', (bkpList) => {
                    let record = tools.plainObject(this.recordStore);
                    tools.cleanRelations(record);
                    tools.clean(record);
                    if (!bkpList) {
                        bkpList = [];
                    } else {
                        bkpList = JSON.parse(bkpList);
                    };
                    let limit = 10;

                    bkpList.splice(0, 0, {time: new Date(), record: record});
                    if (bkpList.length > limit) {
                        bkpList.splice(limit, 1);
                    }
                    let s = JSON.stringify(bkpList);
                    frontTools.setToIndexDB(s, 'bookingBkp', 'bkp')
                });

            } catch (err) {
                frontTools.removeIndexDB();

            }
        },
        showVersionComment () {
            this.showVersionInput = true;
        },
        showVersionList () {
            this.showBookingVersions = true;
        },
        showNotes () {
            this.notes = true;
        },
        shareBooking () {
            this.bookingShare = true;
        },
        showBookingAlerts () {
            this.alerts = true;
        },
        showBookingViews () {
            this.views = true;
        },
        showRecordHistory () {
            this.changes = true;
        },
        showBackups () {
            this.backups = true;
        },
        async closeBookingAlerts () {
            this.alerts = false;
            if (this.alertsNextSave) {
                this.alertsNextSave = false;
                await this.save();
            }
        },
        showBookingFormActions () {
            this.bookingFormActions = [
                {value: 'VIEW', label: 'View', tr: true},
                {value: 'LINK', label: 'Open link', tr: true},
                {value: 'EDIT', label: 'Edit', tr: true},
            ]
        },
        async saveVersion (comment) {
            this.showVersionInput = false;
            let res = await api.get('/api/booking/set_version/' + this.recordStore.id + '/' + comment);
            if (res)  {
                alert(tr('Record Saved'));
            }
        },
        async onMount () {
            let record;
            let model = await api.importMixinModule('booking', 'model');
            if (this.id != 'new') {
                if (!this.version) {
                    record = await model.get({id: this.id, isTemplate: this.template!="NONE"});
                } else {
                    let version = await api.get('/api/bookingversion/' + this.id);
                    let booking = version.Data;
                    record = await new model({record: booking, isTemplate: false, versionDate: version.VersionDate});
                    this.versionId = version.id;
                    this.versionComment = version.Comment;
                }
            } else if (this.newBooking) {
                this.setCurrentTab('cover');
                this.isNew = true;
                record = this.newBooking;
            } else {
                this.setCurrentTab('cover');
                record = await new model({isTemplate: this.template!="NONE"});
                if (this.template) record.TemplateType = this.template;
                this.isNew = true;
            }
            await this.setBooking(record);
            this.afterMount();
        },
        afterMount () {
        },
        async setBooking (record) {
            this.$store.commit('initRecord', {record});
            if (record && record.id && this.recordStore && this.recordStore.operationStatus) {
                await this.$store.dispatch('setBookingForm', {bookingId: this.recordStore.id})
            }
            if (api.costControl && record && record.id && this.recordStore && this.recordStore.notTemplate) {
                this.$store.dispatch('setBookingCost', {booking: this.recordStore})
            }
            this.setRecord();
            if (this.recordStore && this.recordStore.id) {
                //this.getNotes();
                //this.getNotesQuantity();
            }
        },
        async getNotesQuantity () {
            if (!this.recordStore) return;
            if (!this.recordStore.id) return;
            this.qNotes = await api.get('/api/bookingnote/quant/' + this.recordStore.id);
        },
        async getNotes () {
            if (this.recordStore && this.user) {
                let notes = await api.get('/api/bookingnote/', {IncludeClosed: false
                    , filters: JSON.stringify({BookingId: this.recordStore.id, Status: 'CREATED'})})
                if (notes) {
                    for (let note of notes) {
                        let showAlert = false;
                        if (note.Important) showAlert = true;
                        if (note.UserId && this.user.id!=note.UserId) showAlert = false;
                        if (showAlert) {
                            alert(note.Note);
                            continue;
                        };
                        note.Status = 'READED';
                        note.original_id = note.id;
                        await api.post('/api/bookingnote/' + note.id, JSON.stringify(note));
                    }
                }
            }
        },
        currentTabEvent () {
            let self = this;
            setTimeout(() => {
                $('#langTabs .nav-item span').on('click', function(event){
                    let x = $(event.target);
                    let x1;
                    if (!x[0].getAttribute('id')) {
                        x1 = event.target.parentNode;
                    } else {
                        x1 = x[0];
                    }
                    if (x1.getAttribute('id')) self.setCurrentTab(x1.getAttribute('id'));
                });
                $('#langTabs .nav-item span svg').on('click', function(event){
                    let x = $(event.target);
                    let x1;
                    if (!x[0].getAttribute('id')) {
                        x1 = event.target.parentNode;
                    } else {
                        x1 = x[0];
                    }
                    x1 = x1.parentNode;
                    if (x1.getAttribute('id')) self.setCurrentTab(x1.getAttribute('id'));
                });
            }, 1)
        },
        setIntervalTabs () {
            let self = this;
            this.intervalTabs = setInterval(function() {
                let tabs = $('#langTabs .nav-item span');
                if (tabs.length != self.tabsLength) {
                    self.currentTabEvent();
                }
                self.tabsLength = tabs.length;
            }, 200);
        },
        stopIntervalTabs () {
            clearInterval(this.setIntervalTabs)
        },
        closePath () {
            if (this.toPath!=null) {
                this.$router.push({name: this.toPath.name, params: this.toPath.params});
                this.$store.commit('setToPath', null);
            } else {
                let routeName = this.getRouteName(this.$router.currentRoute);
                this.$router.push({ name: routeName});
            }
        },
        replacePath () {
            if (!this.recordStore) return;
            if (!this.recordStore.id) return;
            this.$router.push({ name: this.$router.currentRoute.name, params: { table: this.table, id: this.recordStore.id.toString() }})
        },
        setCurrentTab (tab) {
            this.$store.commit('setCurrentTab', tab);
        },
        getRouteName (currentRoute) {
            let routeName = 'abm-booking';
            if (currentRoute.name == 'sale') routeName = 'abm-sales';
            if (currentRoute.name == 'template') routeName = 'abm-templates';
            if (currentRoute.name == 'my-template') routeName = 'abm-my-templates';
            if (currentRoute.name == 'private-template') routeName = 'abm-private-templates';
            if (currentRoute.name == 'my-booking') routeName = 'abm-my-booking';
            if (currentRoute.name == 'my-sale') routeName = 'abm-my-sales';
            if (currentRoute.name == 'my-file') routeName = 'abm-my-files';
            return routeName;
        },
        setBookingFormAction (value) {
            //this.bookingFormActions = false;
            if (value=='LINK') this.showBookingForm(1);
            if (value=='VIEW') {
                setTimeout(() => {
                    this.showBookingForm(0);
                }, 1000);
            }
            if (value=='EDIT') {
                setTimeout(() => {
                    this.showBookingForm(2);
                }, 1000);
            }
        },
        async showBookingForm (type) {
            let res;
            if (this.recordStore.BookingFormId) {
                res = this.recordStore.BookingFormId;
            } else {
                res = await api.post('/api/booking/create_booking_form/' + this.recordStore.id, {})
                if (res) {
                    this.recordStore.BookingFormId = res
                }
            }
            if (res && type==1) {
                let route = this.$router.resolve({name: 'reservation-form'
                    , params: {hash: this.recordStore.Hash, id: this.recordStore.id, langCode: this.recordStore.Language.Code}})
                window.open(route.href, '_blank');
            } else if (res && !type==1) {
                this.viewBookingForm = res;
            } else if (res && type==2) {
                EventBus.$emit('open-modal-record', {modalRecord: null, modalTable: 'bookingform',
                  modalId: this.recordStore.BookingFormId, closeAction: 'close-bookingform'});
             }
        },
        async runSave () {
            if (this.version) {
                if (this.user.UserName != 'admin') return;  
                let rec = _.cloneDeep(this.recordStore);
                this.recordStore.clean(rec);
                let r = tools.plainObject(rec);
                let res = await api.post('/api/booking/update_version/' + this.versionId, JSON.stringify(r));
                if (res) {
                    alert(res)
                    return;
                }
                return;
            };
            if (this.recordBlocked) {
                //alert(this.recordBlocked);
                //return;
            }
            this.errors = [];
            if (this.recordStore.hasErrors && !this.recordStore.checkFields()) {
                this.alertsNextSave = true;
                this.showBookingAlerts();
            } else {
                await this.save();
            }
        },
        async closeBookingForm () {
            await this.$store.dispatch('setBookingForm', {bookingId: this.recordStore.id});
        },
        afterSave () {
            EventBus.$emit('after-save', true);
        },
    },
    beforeDestroy() {
        EventBus.$off('close-bookingform');
        EventBus.$off('bkp-booking');
        clearInterval(this.recorderInterval);
        clearInterval(this.intervalTabs);
        api.setPayments();
        api.setReceipts();
        //this.$store.commit('initRecord', {record: null});
        this.$store.commit('setBookingForm', null);
    },
}
</script>
