<script>
const abmBooking = importVueComp('components/tools', 'abmBooking', 'custom')
export default {
    name: 'custom-abm-booking',
    mixins: [abmBooking],
    methods: {
        getRouteName (currentRoute) {
            let routeName = abmBooking.methods.getRouteName(currentRoute);
            if (currentRoute.name == 'abm-booking-catalogue') routeName = 'booking-catalogue';
            return routeName;
        },
        getColumns () {
            let columns = this.abm.columnsNames;
            if (this.options.fieldsAbmList) {
                columns = this.options.fieldsAbmList;
            }
            if (window.innerWidth < 500) {
                columns = columns.slice(0, 3);
            }
            if (this.$router.currentRoute.name == "abm-sales" || this.$router.currentRoute.name == "abm-my-sales") {
                columns.push('EndDate')
            }
            this.columnsNames = columns;
        }
    },
}
</script>